<template>
  <div class="MainLayout">
    <div class="container">
      <div class="left">Типо картинка</div>
      <div class="form-container">
        <h2>Пожалуйста, авторизуйтесь в своем профиле или создайте новый</h2>
        <v-snackbar color="red" v-model="showError" timeout="3000" top right multi-line>
          {{ errorMessage }}
          <v-btn class="snackbar-button" @click="showError = false">Закрыть</v-btn>
        </v-snackbar>
        <v-snackbar color="green" v-model="showSuccess" timeout="3000" top right multi-line>
          {{ successMessage }}
          <v-btn class="snackbar-button" @click="showSuccess = false">Закрыть</v-btn>
        </v-snackbar>

        <form @submit.prevent="login">
          <div class="form-group">
            <label for="username">Номер телефона</label>
            <input type="text" id="username" v-model="username" required/>
          </div>
          <div class="form-group">
            <label for="password">Пароль</label>
            <div class="password-container">
              <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required/>
              <div class="eye-container" @click="togglePassword">
                <div :class="['eye', showPassword ? 'eye-closed' : 'eye-open']"></div>
              </div>
            </div>
            <div class="checkbox-container">
              <v-switch v-model="rememberMe" label="Запомнить меня" class="custom-switch"></v-switch>
              <a href="#" class="forgot-password">Забыли пароль?</a>
            </div>
          </div>
          <button type="submit" class="primary-button">Войти</button>
          <button class="secondary-button" @click="goToRegister">Создать новый профиль</button>
          <div class="or">Или</div>
          <button class="vk-button">Войти с помощью VK</button>
        </form>
      </div>
      <div class="right">Инфа о проекте + картинка</div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import authApi from "@/api/AuthApi";
import {VBtn, VSnackbar, VSwitch} from 'vuetify/components';
import {CookieManager} from "@/api/base/CookieManager";
import router from "@/router/router";

const username = ref('');
const password = ref('');
const showPassword = ref(false);
const rememberMe = ref(false);

const showError = ref(false);
const showSuccess = ref(false);
const errorMessage = ref('');
const successMessage = ref('');

const login = async () => {
  try {
    const requestBody = {
      username: username.value,
      password: password.value,
    };

    const response = await authApi.signInUser(requestBody);

    if (response && response.token) {
      CookieManager.setCookie('token', response.token);
      successMessage.value = 'Вход выполнен успешно';
      showSuccess.value = true;

      setTimeout(() => {
        router.push({name: 'home'});
      }, 1000);
    }
  } catch (error) {
    errorMessage.value = 'Ошибка входа. Проверьте данные и попробуйте снова.';
    showError.value = true;
    console.error('Ошибка входа:', error);
  }
};

const goToRegister = () => {
  router.push({ name: 'register' });
};


const togglePassword = () => {
  showPassword.value = !showPassword.value;
};
</script>

<style scoped>
.MainLayout {
  margin: 0 auto;
  max-width: 1760px;
  width: 100%;
  padding: 0 20px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 520px 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1440px;
  align-items: stretch;
}

.left, .right {
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 850px;
}

.form-container {
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 520px;
  margin: 0 auto;
  align-self: center;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 1rem;
}

input {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background-color: #333;
  color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input::placeholder {
  color: #ccc;
}

.primary-button {
  padding: 15px;
  background-color: #ff7e1b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
}

.primary-button:hover {
  background-color: #e66a00;
}

.secondary-button {
  background-color: transparent;
  border: 1px solid #ff7e1b;
  color: #ff7e1b;
  margin-top: 10px;
  padding: 15px;
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
}

.secondary-button:hover {
  background-color: rgba(255, 126, 27, 0.1);
}

.or {
  text-align: center;
  margin: 20px 0;
  color: #ccc;
}

.vk-button {
  background-color: #4a76a8;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
}

.vk-button:hover {
  background-color: #3b5a77;
}

.password-container {
  display: flex;
  align-items: center;
  position: relative;
}

.eye-container {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.eye {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  background-color: #ff7e1b;
  transition: background-color 0.3s ease;
}

.eye::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 60%;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.3s ease;
}

.eye-open::before {
  transform: translate(-50%, -50%) scale(0);
}

.eye-closed::before {
  transform: translate(-50%, -50%) scale(1);
}

.checkbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}


.forgot-password {
  color: #ff7e1b;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

.snackbar-button {
  color: #ffffff;
  background-color: #ff6600;
}

.custom-switch ::v-deep .v-switch__track {
  background-color: #ff6600;
  opacity: 100%;
  height: 25px;
}

.custom-switch ::v-deep .v-switch__thumb {
  background-color: black
}

@media (min-width: 768px) {
  .left, .right {
    display: block;
  }
}
</style>