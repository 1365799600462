<template>
  <div class="TopMenuWrapper">
    <div class="BurgerWrapper" @mouseenter="showBurgerMenu" @mouseleave="hideBurgerMenu">
      <div class="BurgerButton" :class="{ 'active': burgerMenuActive }">
        <div class="fourteen-font">БУРГЕР</div>
      </div>
      <div class="BurgerMenu" :class="{ 'active': burgerMenuActive }" ref="burgerMenu">
        <div class="BurgerMenu-item">
          <router-link to="/chordLibrary" class="BurgerMenu-link">Библиотека аккордов</router-link>
        </div>
        <div class="BurgerMenu-item">
          <router-link to="/tuner" class="BurgerMenu-link">Тюнер</router-link>
        </div>
        <div class="BurgerMenu-item">
          <router-link to="/addSong" class="BurgerMenu-link">Добавить песню</router-link>
        </div>
        <div class="BurgerMenu-item">
          <router-link to="/upload-chords" class="BurgerMenu-link">Добавить аккорды</router-link>
        </div>
      </div>
    </div>

    <div class="MainLogoWrapper">
      <router-link to="/" class="LogoLink">
        <img src="@/assets/icons/ico/logo.ico" alt="Логотип сайта" class="Logo" />
      </router-link>
    </div>

    <div class="PageButtonsWrapper">
      <router-link to="/songsLibrary" class="Navbar-link">
        <button class="Navbar-button">
          <div class="fourteen-font">Песни</div>
        </button>
      </router-link>
      <router-link to="/allGenres" class="Navbar-link">
        <button class="Navbar-button">
          <div class="fourteen-font">Жанры</div>
        </button>
      </router-link>
      <router-link to="/allMusicians" class="Navbar-link">
        <button class="Navbar-button">
          <div class="fourteen-font">Исполнители</div>
        </button>
      </router-link>
    </div>

    <div class="SearchWrapper">
      <input type="text" class="SearchInput" placeholder="Поиск..." />
      <span class="SearchIcon">🔍</span>
    </div>

    <div class="ExtraButtonsWrapper">
      <button class="LangButton">En</button>
      <button class="ThemeButton">🌞</button>
      <div v-if="isSessionActive" class="ProfileWrapper">
        <div class="profile-container" @mouseenter="toggleUserInfo" @mouseleave="toggleUserInfo">
          <img :src="userInfo.photo || '/default-avatar.png'" alt="User Photo" class="profile-circle" @click="goToProfile" />
          <div v-if="showUserInfo" class="username-hover">{{ userInfo.username }}</div>
        </div>
      </div>
      <button v-else class="JoinButton" @click="$router.push('/register')">Присоединиться</button>
    </div>
  </div>

  <div class="Content">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { UserApi } from '@/api/UserApi';
import { CookieManager } from "@/api/base/CookieManager";

export default defineComponent({
  name: 'Layout',
  setup() {
    const burgerMenuActive = ref(false);
    const userInfo = ref({
      username: '',
      photo: null as string | null,
    });

    const isSessionActive = ref(false);
    const showUserInfo = ref(false);

    const showBurgerMenu = () => {
      burgerMenuActive.value = true;
    };

    const hideBurgerMenu = () => {
      burgerMenuActive.value = false;
    };

    const toggleUserInfo = () => {
      showUserInfo.value = !showUserInfo.value;
    };

    const goToProfile = () => {
      if (isSessionActive.value) {
        window.location.href = '/my-profile';
      }
    };

    const checkSession = async () => {
      const token = CookieManager.getCookie('token');
      if (!token) {
        return; // Пользователь не залогинен, не загружаем данные
      }

      try {
        const userApi = new UserApi();
        const sessionData = await userApi.getUserInfo();

        if (sessionData) {
          userInfo.value = sessionData;
          isSessionActive.value = true;
        }
      } catch (error) {
        console.error('Ошибка получения сессии пользователя:', error);
        isSessionActive.value = false;
      }
    };

    onMounted(() => {
      checkSession();
    });

    return {
      burgerMenuActive,
      showBurgerMenu,
      hideBurgerMenu,
      userInfo,
      isSessionActive,
      showUserInfo,
      toggleUserInfo,
      goToProfile,
    };
  },
});
</script>


<style scoped>
.TopMenuWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(17, 17, 17, 1);
  padding: 0 20px;
  max-height: 75px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.BurgerWrapper {
  flex: 0 0 5%;
  position: relative;
}

.MainLogoWrapper {
  flex: 0 0 5%;
}

.MainLogoWrapper img {
  width: 100%;
}

.PageButtonsWrapper {
  flex: 0 0 28%;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.Navbar-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.SearchWrapper {
  flex: 0 0 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.SearchInput {
  width: 100%;
  padding: 6px 12px;
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.SearchIcon {
  position: absolute;
  right: 10px;
  color: white;
  pointer-events: none;
}

.ExtraButtonsWrapper {
  flex: 0 0 20%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 5%;
}

.LangButton,
.ThemeButton,
.JoinButton {
  background-color: transparent;
  border: none;
  color: rgba(255, 126, 7, 1);
  cursor: pointer;
  font-size: 16px;
}

.JoinButton {
  background-color: rgba(255, 126, 7, 1);
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
}

.profile-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-container {
  position: relative;
  display: inline-block;
}

.username-hover {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.profile-container:hover .username-hover {
  opacity: 1;
}

.BurgerMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: rgba(17, 17, 17, 1);
  flex-direction: column;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 10;
  pointer-events: none;
}

.BurgerButton {
  border: none;
  color: transparent;
  cursor: pointer;
  width: 30px;
  height: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BurgerButton::before,
.BurgerButton::after,
.BurgerButton div {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  transition: transform 0.3s ease;
}

.BurgerButton::before {
  top: 2px;
}

.BurgerButton::after {
  bottom: 2px;
}

.BurgerButton div {
  top: 50%;
  transform: translateY(-50%);
}

.BurgerButton.active::before,
.BurgerButton.active::after,
.BurgerButton.active div {
  transform: rotate(180deg);
}

.BurgerWrapper:hover .BurgerMenu {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.BurgerMenu.active {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.BurgerMenu-item {
  padding: 10px 20px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.BurgerMenu.active .BurgerMenu-item {
  opacity: 1;
  transform: translateY(0);
}

.BurgerMenu-link {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.BurgerMenu-link:hover {
  color: #ccc;
}
</style>
