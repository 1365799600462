<template>
  <div class="form-container">
    <h2>Регистрация</h2>

    <v-snackbar color="red" v-model="showError" timeout="3000" top right multi-line>
      {{ errorMessage }}
      <v-btn class="snackbar-button" @click="showError = false">Закрыть</v-btn>
    </v-snackbar>
    <v-snackbar color="green" v-model="showSuccess" timeout="3000" top right multi-line>
      {{ successMessage }}
      <v-btn class="snackbar-button" @click="showSuccess = false">Закрыть</v-btn>
    </v-snackbar>

    <form @submit.prevent="register">
      <div class="form-group">
        <label for="firstname">Имя</label>
        <input type="text" id="firstname" v-model="form.firstname" />
      </div>
      <div class="form-group">
        <label for="lastname">Фамилия</label>
        <input type="text" id="lastname" v-model="form.lastname" />
      </div>
      <div class="form-group">
        <label for="surname">Отчество</label>
        <input type="text" id="surname" v-model="form.surname" />
      </div>
      <div class="form-group">
        <label for="username">Имя пользователя</label>
        <input type="text" id="username" v-model="form.username" required />
      </div>
      <div class="form-group">
        <label for="password">Пароль</label>
        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="form.password" required />
      </div>
      <div class="form-group">
        <label for="confirmPassword">Подтвердить пароль</label>
        <input :type="showPassword ? 'text' : 'password'" id="confirmPassword" v-model="form.confirmPassword" required />
      </div>
      <div class="form-group">
        <label>
          <input type="checkbox" v-model="showPassword" /> Показать пароль
        </label>
      </div>

      <div v-if="passwordMismatch" class="form-group error-message">
        Пароли не совпадают
      </div>

      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" id="email" v-model="form.email" />
      </div>
      <div class="form-group">
        <label for="phone">Телефон</label>
        <input type="text" id="phone" v-model="form.phone" />
      </div>
      <div class="form-group">
        <label for="country">Страна</label>
        <input type="text" id="country" v-model="form.country" />
      </div>
      <div class="form-group">
        <label for="photo">Фотография</label>
        <input type="file" id="photo" @change="handlePhotoChange" accept="image/*" />
      </div>

      <button type="submit" :disabled="passwordMismatch">Зарегистрироваться</button>

      <div class="login-link">
        <p>Уже зарегистрированы? <router-link to="/login" class="login-link-text">Войти</router-link></p>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { AuthApi } from "@/api/AuthApi";
import { VBtn, VSnackbar } from 'vuetify/components';

export default {
  setup() {
    const form = ref({
      firstname: '',
      lastname: '',
      surname: '',
      username: '',
      email: '',
      phone: '',
      country: '',
      password: '',
      confirmPassword: '',
      photo: null,
    });

    const showPassword = ref(false);
    const showError = ref(false);
    const showSuccess = ref(false);
    const errorMessage = ref('');
    const successMessage = ref('');

    const handlePhotoChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        form.value.photo = file;
      }
    };

    const passwordMismatch = computed(() => form.value.password !== form.value.confirmPassword);

    const register = async () => {
      if (passwordMismatch.value) return;

      const registrationData = {
        photo: form.value.photo,
        user: { ...form.value }
      };

      try {
        const authApi = new AuthApi();
        await authApi.signUpUser(registrationData);
        successMessage.value = 'Регистрация прошла успешно';
        showSuccess.value = true;
      } catch (error) {
        errorMessage.value = 'Ошибка при регистрации. Попробуйте снова.';
        showError.value = true;
      }
    };

    return {
      form,
      showPassword,
      handlePhotoChange,
      register,
      passwordMismatch,
      showError,
      showSuccess,
      errorMessage,
      successMessage,
    };
  },
  components: {
    VBtn,
    VSnackbar,
  }
};
</script>

<style scoped>
.form-container {
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

h2 {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  background-color: #333;
  color: white;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  background-color: #ff7e07;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #e66a00;
}

.error-message {
  color: red;
}

.snackbar-button {
  color: white;
  background-color: #ff6600;
}

.login-link {
  margin-top: 20px;
  text-align: center;
}

.login-link-text {
  color: #ff7e07;
  text-decoration: none;
  font-weight: bold;
}

.login-link-text:hover {
  color: #e66a00;
}
</style>
