<template>
  <v-container class="chord-form-container">
    <v-snackbar color="red" v-model="showError" timeout="3000" top right multi-line>
      {{ errorMessage }}
      <v-btn class="snackbar-button" @click="showError = false">Закрыть</v-btn>
    </v-snackbar>
    <v-snackbar color="green" v-model="showSuccess" timeout="3000" top right multi-line>
      {{ successMessage }}
      <v-btn class="snackbar-button" @click="showSuccess = false">Закрыть</v-btn>
    </v-snackbar>

    <v-row class="form-section" align="center" justify="center">
      <v-col cols="12">
        <v-file-input
            v-model="chordFiles"
            label="Выберите файлы аккордов и изображения"
            accept=".txt, .png, .jpg, .jpeg, .gif"
            multiple
        />
        <v-switch
            v-model="needUpdate"
            label="Необходимость обновления"
            class="custom-switch"
        />
        <v-list v-if="chordPreviews.length">
          <v-list-item
              v-for="(chord, index) in chordPreviews"
              :key="index"
          >
            <v-list-item-title>{{ chord.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-btn @click="saveChords" class="save-button">Сохранить аккорды и изображения</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import ChordApi from '@/api/ChordApi';

import {
  VBtn,
  VSwitch,
  VCol,
  VContainer,
  VFileInput,
  VList,
  VListItem,
  VListItemTitle,
  VRow,
  VSnackbar,
} from 'vuetify/components';

const chordFiles = ref<File[]>([]);
const chordPreviews = ref<{ name: string }[]>([]);
const showError = ref(false);
const showSuccess = ref(false);
const errorMessage = ref('');
const successMessage = ref('');
const needUpdate = ref(false);

watch(chordFiles, (newFiles) => {
  chordPreviews.value = newFiles.map(file => ({ name: file.name }));
});

const saveChords = async () => {
  const formData = new FormData();
  chordFiles.value.forEach(file => formData.append('chords', file));

  try {
    await ChordApi.saveChords(chordFiles.value, needUpdate.value);
    successMessage.value = 'Аккорды и изображения успешно сохранены';
    showSuccess.value = true;
    chordFiles.value = [];
    chordPreviews.value = [];
  } catch (error) {
    showError.value = true;
    errorMessage.value = 'Ошибка при сохранении аккордов и изображений';
    console.error('Ошибка при сохранении аккордов и изображений:', error);
  }
};

</script>

<style scoped>
.chord-form-container {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #121212;
  padding: 20px;
  border-radius: 8px;
}

.form-section {
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 8px;
  color: #ffffff;
}

.save-button {
  color: #ffffff;
  background-color: #ff6600;
  margin-top: 20px;
}

.snackbar-button {
  color: #ffffff;
  background-color: #ff6600;
}

.v-file-input {
  color: #ffffff;
}

.custom-switch ::v-deep .v-switch__track {
  background-color: #ff6600;
  opacity: 100%;
  height: 25px;
}

.custom-switch ::v-deep .v-switch__thumb {
  background-color: black;
}
</style>
