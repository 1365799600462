<template>
  <v-container class="add-song-container">
    <v-snackbar color="red" v-model="showError" timeout="3000" top right multi-line>
      {{ errorMessage }}
      <v-btn class="snackbar-button" @click="showError = false">Закрыть</v-btn>
    </v-snackbar>
    <v-snackbar color="green" v-model="showSuccess" timeout="3000" top right multi-line>
      {{ successMessage }}
      <v-btn class="snackbar-button" @click="showSuccess = false">Закрыть</v-btn>
    </v-snackbar>


    <v-row class="top-bar" align="center" justify="space-between">
      <v-col class="step-section">
        <v-chip class="step-display">{{ currentStepTitle }}</v-chip>
      </v-col>
      <v-col class="button-section" cols="auto">
        <v-btn @click="goToPreviousStep" :disabled="isFirstStep" class="back-button">Назад</v-btn>
        <v-btn @click="goToNextStep" :disabled="!isNextStepActive" class="forward-button">
          {{ isLastStep ? 'Сохранить' : 'Далее' }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="form-section" align="center" justify="center">
      <v-col cols="12">
        <div v-if="currentStep === 0">
          <v-text-field
              v-model="authorsSearchQuery"
              label="Автор"
              placeholder="Введите имя автора"
              clearable
              append-inner-icon="mdi-magnify"
              @input="onAuthorInputChange"
              class="search-field"
          />

          <v-list v-if="authors.length" class="author-list">
            <v-list-item
                v-for="author in authors"
                :key="author.id"
                @click="selectAuthor(author)"
                class="author-item"
            >
              <v-list-item-title class="author-item-title">{{ author.pseudonym }}</v-list-item-title>
            </v-list-item>
          </v-list>

          <v-btn
              v-if="!authors.length && !selectedAuthor && authorsSearchQuery"
              @click="openAuthorModal"
              class="create-author-button"
          >
            Создать автора
          </v-btn>

          <v-text-field
              v-model="albumsSearchQuery"
              label="Альбом"
              :disabled="!selectedAuthor"
              @input="onAlbumInputChange"
              class="album-field"
          />

          <v-list v-if="albums.length" class="album-list">
            <v-list-item
                v-for="album in albums"
                :key="album.id"
                @click="selectAlbum(album)"
                class="album-item"
            >
              <v-list-item-title class="album-item-title">{{ album.name }}</v-list-item-title>
            </v-list-item>
          </v-list>

          <v-btn
              v-if="selectedAuthor && !albums.length && !selectedAlbum && albumsSearchQuery"
              @click="openAlbumModal"
              class="create-album-button"
          >
            Создать альбом
          </v-btn>

          <v-text-field v-model="newSong.song.name" label="Название песни"/>

          <v-text-field v-model="newSong.song.tempoBPM" label="Темп (уд. мин.)"/>

          <v-select
              v-model="newSong.song.subgenre"
              :items="formattedSubgenres"
              label="Жанр"
              :loading="!formattedSubgenres.length"
              :disabled="!formattedSubgenres.length"
          />

          <v-text-field
              v-model="newSong.song.difficultInStars"
              label="Уровень сложности"
              @input="validateAndCleanStars"
              :error-messages="difficultInStarsError"
          />

          <v-text-field
              v-model="newSong.song.sampleYoutubeLink"
              label="Ссылка на видео кавер песни"
          />

          <v-file-input
              v-model="newSong.photo"
              label="Обложка песни"
              accept="image/*"
              @change="previewSongPhoto"
          />
          <v-img v-if="songPhotoPreview" :src="songPhotoPreview" class="photo-preview"/>
        </div>

        <div v-if="currentStep === 1">
          <v-row class="form-section" align="center" justify="center">
            <v-col cols="12">
              <div v-if="currentStep === 1">
                <v-row class="form-section" align="center" justify="center">
                  <v-col cols="12">
                    <v-text-field
                        v-model="newSong.song.description"
                        label="Описание песни"
                    />

                    <v-btn class="add-remove-button" @click="addSongLine">+</v-btn>
                    <v-btn class="add-remove-button" @click="removeSongLine">-</v-btn>

                    <!-- Формируем компонент для каждой строки песни -->
                    <v-form ref="chordsForm" @submit.prevent="saveSong">
                      <v-row
                          v-for="(line, index) in newSongLines"
                          :key="index"
                          class="line-row"
                      >
                        <v-col cols="1">
                          <v-btn class="block-selector" @click="toggleSongBlock(index)">
                            {{ line.songBlock }}
                          </v-btn>
                        </v-col>
                        <v-col cols="11">

                          <ChordsEditor
                              :line="line"
                              :index="index"
                              @update-chords="updateChordPositions(line, $event)"
                          />

                          <v-text-field
                              v-model="line.line"
                              label="Строка текста песни"
                              class="song-line-field"
                          />
                        </v-col>
                      </v-row>

                    </v-form>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="isAuthorModalOpen" max-width="600px">
      <v-card>
        <v-card-title>Создание нового автора</v-card-title>
        <v-card-text>
          <v-form ref="newAuthorForm" @submit.prevent="saveAuthor">
            <v-text-field v-model="newAuthor.author.name" label="Имя исполнителя"/>
            <v-text-field v-model="newAuthor.author.pseudonym" label="Псевдоним"/>
            <v-text-field v-model="safeYearsOfActivity.start" label="Начало активности"/>
            <v-text-field v-model="safeYearsOfActivity.end" label="Конец активности"/>
            <v-text-field v-model="newAuthor.author.description" label="Описание"/>
            <v-text-field v-model="newAuthor.author.birthDate" label="Дата рождения"/>
            <v-select
                v-model="newAuthor.author.country"
                :items="countries"
                item-value="code"
                item-text="name"
                label="Страна"
            />
            <v-file-input
                v-model="newAuthor.photo"
                label="Фотография"
                accept="image/*"
                @change="previewAuthorPhoto"
            />
            <v-img v-if="authorPhotoPreview" :src="authorPhotoPreview" class="photo-preview"/>
            <v-btn type="submit" class="save-button">Сохранить</v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeAuthorModal">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isAlbumModalOpen" max-width="600px">
      <v-card>
        <v-card-title>Создание нового альбома</v-card-title>
        <v-card-text>
          <v-form ref="newAlbumForm" @submit.prevent="saveAlbum">
            <v-text-field v-model="newAlbum.album.name" label="Название альбома"/>
            <v-text-field v-model="newAlbum.album.description" label="Описание альбома"/>
            <v-text-field v-model="newAlbum.album.release" label="Дата релиза"/>
            <v-file-input
                v-model="newAlbum.photo"
                label="Фотография"
                accept="image/*"
                @change="previewAlbumPhoto"
            />
            <v-img v-if="albumPhotoPreview" :src="albumPhotoPreview" class="photo-preview"/>
            <v-btn type="submit" class="save-button">Сохранить</v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeAlbumModal">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup lang="ts">
import {computed, onMounted, ref, Ref} from 'vue';
import {AuthorApi} from '@/api/AuthorApi';
import {AlbumApi} from '@/api/AlbumApi';
import {SongApi} from '@/api/SongApi';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VChip,
  VCol,
  VContainer,
  VDialog,
  VFileInput,
  VForm,
  VImg,
  VList,
  VListItem,
  VListItemTitle,
  VRow,
  VSelect,
  VSnackbar,
  VTextField,
} from 'vuetify/components';
import type {components} from 'backend-types';
import {GenreApi} from "@/api/GenreApi";
import ChordsEditor from "@/components/add-song/ChordsEditor.vue";

type AuthorDynamicPreviewResponse = components['schemas']['AuthorDynamicPreviewResponse'];
type AuthorSavingRequest = components['schemas']['AuthorSavingRequest'];
type AlbumDynamicPreviewResponse = components['schemas']['AlbumDynamicPreviewResponse'];
type AlbumSavingRequest = components['schemas']['AlbumSavingRequest'];
type SongSavingRequest = components['schemas']['SongSavingRequest'];
type ChordWithPosition = components['schemas']['ChordWithPosition'];
type LinesWithChords = {
  songBlock: "INTRO" | "VERSE" | "CHORUS" | "BRIDGE" | "END";
  line: string;
  chordWithPositions: ChordWithPosition[];
};
type YearsOfActivity = components['schemas']['YearsOfActivity'] | null;
type GenreResponse = components["schemas"]["GenreResponse"];

const authorApi = new AuthorApi();
const albumApi = new AlbumApi();
const songApi = new SongApi();
const genreApi = new GenreApi();

type AuthorSavingRequestFormData = {
  photo: File;
  author: Omit<AuthorSavingRequest, 'photo'>;
};

type SongSavingRequestFormData = {
  photo: File;
  song: Omit<SongSavingRequest, 'photo'>;
};

type AlbumSavingRequestFormData = {
  photo: File;
  album: Omit<AlbumSavingRequest, 'photo'>;
};

const emptySongPhoto = new File([], "");
const emptyAuthorPhoto = new File([], "");
const emptyAlbumPhoto = new File([], "");
const currentStep = ref(0);
const authorsSearchQuery = ref('');
const albumsSearchQuery = ref('');
const authors = ref<AuthorDynamicPreviewResponse[]>([]);
const selectedAuthor = ref<AuthorDynamicPreviewResponse | null>(null);
const albums = ref<AlbumDynamicPreviewResponse[]>([]);
const selectedAlbum = ref<AlbumDynamicPreviewResponse | null>(null);
let countries: string[] = [];
let songBlock: ("INTRO" | "VERSE" | "CHORUS" | "BRIDGE" | "END")[] = ["INTRO", "VERSE", "CHORUS", "BRIDGE", "END"];
const genres = ref<GenreResponse[]>([]);

const steps = ['Данные о песне', 'Аккорды песни'];

const newSongLines = ref<(LinesWithChords & { chordsInput: string })[]>([{
  songBlock: 'INTRO',
  line: '',
  chordWithPositions: [],
  chordsInput: ''
}]);

const toggleSongBlock = (index: number) => {
  const currentBlock = newSongLines.value[index].songBlock;
  const nextIndex = (songBlock.indexOf(currentBlock) + 1) % songBlock.length;
  newSongLines.value[index].songBlock = songBlock[nextIndex];
};

const addSongLine = () => {
  newSongLines.value.push({
    songBlock: "VERSE",
    line: '',
    chordWithPositions: [],
    chordsInput: ''
  });
};

const removeSongLine = () => {
  if (newSongLines.value.length > 1) {
    newSongLines.value.pop();
  }
};

const updateChordPositions = (line: LinesWithChords & { chordsInput: string }, updatedChords: ChordWithPosition[]) => {
  line.chordWithPositions = updatedChords;
};

const newSong = ref<SongSavingRequestFormData>({
  photo: emptySongPhoto,
  song: {
    authorPseudonym: null,
    albumName: null,
    name: '',
    description: '',
    sampleYoutubeLink: '',
    difficultInStars: '',
    subgenre: '',
    tempoBPM: 0,
    havingObscene: false,
    text: {
      linesWithChords: [],
    },
  },
});

const yearsOfActivity = ref<YearsOfActivity>({
  start: '',
  end: '',
});

const safeYearsOfActivity = computed(() => {
  return newAuthor.value.author.yearsOfActivity || {start: '', end: ''};
});

const newAuthor = ref<AuthorSavingRequestFormData>({
  photo: emptyAuthorPhoto,
  author: {
    name: '',
    pseudonym: '',
    yearsOfActivity: yearsOfActivity.value,
    description: null,
    country: 'WITHOUT_COUNTRY',
    birthDate: null,
  },
});

const newAlbum = ref<AlbumSavingRequestFormData>({
  photo: emptyAlbumPhoto,
  album: {
    authorPseudonym: '',
    name: '',
    description: null,
    release: null,
  },
});

const showError = ref(false);
const showSuccess = ref(false);
const successMessage = ref('');
const errorMessage = ref('');
const authorPhotoPreview = ref<string | null>(null);
const albumPhotoPreview = ref<string | null>(null);
const songPhotoPreview = ref<string | null>(null);
const isAuthorModalOpen = ref(false);
const isAlbumModalOpen = ref(false);

const isFirstStep = computed(() => currentStep.value === 0);
const isLastStep = computed(() => currentStep.value === steps.length - 1);
const isNextStepActive = computed(() => {
  if (currentStep.value === 0) {
    return selectedAuthor.value;
  }
  return true;
});

const currentStepTitle = computed(() => steps[currentStep.value]);

const fetchCountries = async () => {
  try {
    countries = await authorApi.fetchAuthorCountries();
  } catch (error) {
    console.error('Ошибка при загрузке списка стран:', error);
  }
};

const fetchSongBlocks = async () => {
  try {
    songBlock = ["INTRO", "VERSE", "CHORUS", "BRIDGE", "END"];
  } catch (error) {
    console.error('Ошибка при загрузке списка блоков песен:', error);
  }
};

const fetchSubgenres = async () => {
  try {
    genres.value = await genreApi.fetchAllSongGenres();
    console.log('Загруженные жанры:', genres.value); // Данные должны быть корректно загружены
  } catch (error) {
    console.error('Ошибка при загрузке списка жанров:', error);
  }
};

const formattedSubgenres = computed(() => {
  if (!genres.value.length) {
    console.error("Данные genres не загружены");
    return [];
  }

  return genres.value.flatMap((genre) => {
    if (!genre.subgenres || !genre.subgenres.length) {
      console.warn(`Нет поджанров для жанра: ${genre.name}`);
      return [];
    }

    return genre.subgenres.map((subgenre) => subgenre.name);
  });
});


onMounted( () => {
  fetchCountries();
  fetchSongBlocks();
  fetchSubgenres();
});

const searchAuthors = async () => {
  if (!authorsSearchQuery.value) {
    authors.value = [];
    return;
  }

  try {
    const limit = 10;
    authors.value = await authorApi.fetchAuthorsByFirstLetters(authorsSearchQuery.value, limit);
  } catch (error) {
    console.error('Ошибка при поиске авторов:', error);
  }
};

const searchAlbums = async () => {
  if (!selectedAuthor.value || !albumsSearchQuery.value) {
    albums.value = [];
    return;
  }
  try {
    if (selectedAuthor.value.pseudonym != null) {
      albums.value = await albumApi.fetchAlbumsByAuthorAndNameFirstLetters(
          selectedAuthor.value.pseudonym,
          albumsSearchQuery.value
      );
    }
  } catch (error) {
    console.error('Ошибка при поиске альбомов:', error);
  }
};

const selectAuthor = (author: AuthorDynamicPreviewResponse) => {
  selectedAuthor.value = author;
  if (author.pseudonym != null) {
    authorsSearchQuery.value = author.pseudonym;
    newAlbum.value.album.authorPseudonym = author.pseudonym;
    newSong.value.song.authorPseudonym = author.pseudonym;
  }
  selectedAlbum.value = {id: 0, name: ''};
  albums.value = [];
  authors.value = [];
};

const selectAlbum = (album: AlbumDynamicPreviewResponse) => {
  selectedAlbum.value = album;
  albums.value = [];
  albumsSearchQuery.value = album.name;
  newSong.value.song.albumName = album.name;
};

const onAuthorInputChange = () => {
  if (selectedAuthor.value) {
    clearSelectedAuthor();
  }
  searchAuthors();
};

const onAlbumInputChange = () => {
  if (selectedAlbum.value) {
    clearSelectedAlbum();
  }
  searchAlbums();
};

const clearSelectedAuthor = () => {
  selectedAuthor.value = null;
  newAlbum.value.album.authorPseudonym = '';
  newSong.value.song.authorPseudonym = '';
  albums.value = [];
  albumsSearchQuery.value = '';
};

const clearSelectedAlbum = () => {
  selectedAlbum.value = null;
  newSong.value.song.albumName = '';
  albumsSearchQuery.value = '';
};


const openAlbumModal = () => {
  isAlbumModalOpen.value = true;
};

const goToNextStep = () => {
  if (!isNextStepActive.value) return;

  if (isLastStep.value) {
    saveSong();
  } else {
    currentStep.value++;
  }
};

const goToPreviousStep = () => {
  if (!isFirstStep.value) currentStep.value--;
};

const previewAuthorPhoto = () => {
  if (newAuthor.value.photo) {
    previewImage(newAuthor.value.photo, authorPhotoPreview);
  }
};

const previewAlbumPhoto = () => {
  if (newAlbum.value.photo) {
    previewImage(newAlbum.value.photo, albumPhotoPreview);
  }
};

const previewSongPhoto = () => {
  if (newSong.value.photo) {
    previewImage(newSong.value.photo, songPhotoPreview);
  }
};

const previewImage = (file: File | null, previewRef: Ref<string | null>) => {
  if (!file || !(file instanceof Blob)) {
    console.error('Invalid file input. Expected a Blob or File.');
    return;
  }

  const reader = new FileReader();
  reader.onload = () => (previewRef.value = reader.result as string);
  reader.readAsDataURL(file);
};

const openAuthorModal = () => {
  isAuthorModalOpen.value = true;
};

const closeAuthorModal = () => {
  isAuthorModalOpen.value = false;
};
const closeAlbumModal = () => {
  isAlbumModalOpen.value = false;
};

const difficultInStarsError = ref<string | null>(null);

const validateAndCleanStars = () => {
  let value = newSong.value.song.difficultInStars;
  const cleanedValue = value.replace(/[^*]/g, '');

  if (cleanedValue !== value) {
    newSong.value.song.difficultInStars = cleanedValue;
  }

  difficultInStarsError.value =
      cleanedValue.length >= 1 && cleanedValue.length <= 5
          ? null
          : 'Поле должно содержать от 1 до 5 звездочек';
};

const saveAuthor = async () => {
  try {
    const response = await authorApi.saveAuthor(newAuthor.value);

    if (response && response.pseudonym) {
      authorsSearchQuery.value = response.pseudonym;
      newAlbum.value.album.authorPseudonym = response.pseudonym;
      selectedAuthor.value = {
        id: response.id,
        pseudonym: response.pseudonym,
      };
      newSong.value.song.authorPseudonym = response.pseudonym;
      albums.value = [];
    }

    successMessage.value = 'Автор успешно создан';
    showSuccess.value = true;
    closeAuthorModal();
  } catch (error) {
    showError.value = true;
    errorMessage.value = 'Ошибка при создании автора.';
    console.error('Ошибка при создании автора:', error);
  }
};

const saveAlbum = async () => {
  try {
    const response = await albumApi.saveAlbum(newAlbum.value);

    if (response && response.name) {
      albumsSearchQuery.value = response.name;
      selectedAlbum.value = {
        ...newAlbum.value.album,
        id: response.id,
        name: response.name,
      };
      newSong.value.song.albumName = response.name;
      albums.value = [];
    }

    successMessage.value = 'Альбом успешно создан';
    showSuccess.value = true;
    closeAlbumModal();
  } catch (error) {
    showError.value = true;
    errorMessage.value = 'Ошибка при создании альбома.';
    console.error('Ошибка при создании альбома:', error);
  }
};

const saveSong = async () => {
  try {
    newSong.value.song.text.linesWithChords = newSongLines.value.map((line) => ({
      songBlock: line.songBlock,
      line: line.line,
      chordWithPositions: line.chordWithPositions,
    }));

    await songApi.saveSong(newSong.value);
    successMessage.value = 'Песня успешно сохранена';
    showSuccess.value = true;
    console.log('Song saved:', newSong.value);
  } catch (error) {
    showError.value = true;
    errorMessage.value = 'Ошибка при сохранении песни.';
    console.error('Ошибка при сохранении песни:', error);
  }
};
</script>

<style scoped>
.add-song-container {
  max-width: 1760px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #121212;
}

.top-bar {
  background-color: #1e1e1e;
  padding: 10px;
  height: 70px;
  color: #ffffff;
}

.step-section {
  flex-grow: 1;
  color: #ffffff;
}

.button-section {
  display: flex;
  gap: 10px;
}

.form-section {
  height: 850px;
  overflow-y: auto;
  background-color: #1c1c1c;
  padding: 20px;
  border-radius: 8px;
  color: #ffffff;
}

.search-field,
.album-field {
  width: 100%;
  background-color: #2c2c2c;
  color: #ffffff;
  border: 1px solid #444444;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.search-field:focus,
.album-field:focus {
  border-color: #ff6600;
}

.author-list {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 10px;
  padding: 0;
  list-style: none;
  background-color: #2c2c2c;
  border: 1px solid #444444;
  border-radius: 4px;
}

.author-item,
.album-item {
  color: #ffffff;
  cursor: pointer;
  background-color: #444444;
  padding: 12px;
  margin-bottom: 5px;
  border-radius: 4px;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
}

.author-item:hover,
.album-item:hover {
  background-color: #555555;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.author-item-title,
.album-item-title {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
}

.photo-preview {
  width: 150px;
  height: 150px;
  margin-top: 10px;
  border-radius: 8px;
  background-color: #2c2c2c;
  border: 1px solid #444444;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff6600;
}

.forward-button,
.back-button,
.save-button,
.create-author-button,
.create-album-button,
.snackbar-button {
  width: 100px;
  color: #ffffff;
  background-color: #ff6600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  border-radius: 4px;
}

.block-selector {
  color: #ff6600;
  width: 100px;
  height: 50px;
  background-color: #2c2c2c;
  border: 1px solid #444444;
}

.song-line-field,
.chord-line-field {
  background-color: #2c2c2c;
  color: #ffffff;
  border: 1px solid #444444;
  width: calc(100% - 20px);
  padding: 12px;
  font-size: 16px;
  border-radius: 4px;
}

.line-row {
  margin-bottom: 20px;
}

.add-remove-button {
  color: #ff6600;
  width: 50px;
  height: 50px;
  background-color: #333333;
  border: 1px solid #444444;
  border-radius: 4px;
}
</style>
